import img_left from '../images/home_livingroom.png';
import img_center from '../images/home_room.png';
import img_right from '../images/home_bar.png';
import frase from '../images/frase_home.png';
import '../css/home.css';

const Home = () =>{
  return(
    <div className="home-container">
      <div className="image-container">
      <div className='home-image'>
          <img src={img_left} alt="" />
        </div>
        <div className='home-image'>
          <img src={img_center} alt="" />
        </div>
        <div className='home-image'>
          <img src={img_right} alt="" />  
        </div>   
      </div> 
      <div className="text-container">
        <div className="phrase"
        style={{ 
          backgroundImage: '../images/logoblanco.png',
          
        }}>
            <img src={frase} alt="" height={100}/>
        </div>
        <div className="description">
          <div className="title">LED<strong>LIGHTING</strong></div>
          <div className="subtitle">SOLUTIONS</div>
          <div className="description-text">
          The ambiance and aesthetics of your apartment, house, or office are profoundly shaped 
          by the quality and design of the lighting you select. Your lighting choices have the power 
          to transform a space, making it feel cozy and inviting, or modern and sophisticated. 
          That's where we step in, dedicated to helping you bring your interior design visions to life. 
          Our mission is to provide you with not just illumination, but the right kind of illumination –
           tailored to the unique requirements of each area in your living or workspace.</div>
        </div>
      </div>
    </div>
  )
}

export default Home;