import '../css/store.css';
import xlogo from '../images/X_luxury.png';

const Store = ({data}) => {
  const {storeName, address, phone, email, web} = data;
  const addressWhole = address.addressLine1 + ',' + address.city + ',' + address.stateInitials + ',' + address.zip;
return (
  <div className="store-container">
    <div className="store-name">
      <img src={xlogo} width={25} height={25} alt="" /> 
      {storeName}
    </div>
    <div className="store-address">
      {addressWhole}
    </div>
    <div className="store-phone">Phone: {phone}</div>
    <div className="store-web">{web}</div>
    <div className="store-email">{email}</div>
    <div className="store-viewmap"><a href={'https://maps.google.com/maps?q='+addressWhole} target="_blank">View on map</a></div>
  </div>
  )
}

export default Store