import "../css/contactus.css";
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [okMessage, setOkMessage] = useState('');

  const form = useRef();

  const handleSend = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_info_luxuryledus', 'web_contact', form.current, 'p2I2wRJwN5KxS2Tj1')
      .then((result) => {
          console.log(result.text);
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setOkMessage('Message has been sent')
      }, (error) => {
          console.log(error.text);
      });

  }
  return(
    <div className="main-container-contact">
    <div className="form-container">
      <div className="empty-column"></div>
      <div className="main-column" >
        <div className="text-container-contact">We are for helping our customers and users. Please leave us a message and we are going to contact you ASAP</div>
        <form ref={form} onSubmit={handleSend}>

          <div className="input-container">
            <input name="from_name" type="text"  className="contact-input" placeholder="Name *" onChange={e => setName(e.target.value)}/>
          </div>
          <div className="input-container">
            <input type="text"  name="from_email" className="contact-input" placeholder="Email *" onChange={e => setEmail(e.target.value)}/>
          </div>
          <div className="input-container">
            <input type="text"  name="from_phone" className="contact-input" placeholder="Phone" onChange={e => setPhone(e.target.value)}/>
          </div>
          <div className="input-container">
            <textarea  cols="30" rows="10" name="message" placeholder="Message..." onChange={e => setMessage(e.target.value)}></textarea>
          </div>
          <div className="input-container">
            <input className="contact-button" type="submit" value="Send" />
          </div>
        </form>
        
        <div className="ok-message">
          {okMessage}
        </div>
      </div>
    </div>
    <div className="logos">
    </div>
    </div>
    
  )
}

export default ContactUs;