import '../css/aboutus.css';
import aboutus from '../images/aboutus.jpg';
import aboutus_title_gray from '../images/aboutus-title-gray.png';
import about_us_company from '../images/about-us-company.png';

const AboutUs = () =>{
  return(
    <div className="about-container">
      <div className="left-container">
        <div className="image-container">
        <img src={aboutus} alt="" />
        </div>
        <div className="title-container">
        <img src={about_us_company} alt="" />
        </div>
      </div>
      <div className="right-container">
        <div className="description-title">
        <img src={aboutus_title_gray} alt="" />
        </div>
        <div className="description-text">

      <p>
              We are a company with over 10 years of experience in the LED market. 
              This extensive tenure has equipped us with the experience and technical 
              knowledge required to provide our customers with high-quality, state-of-the-art products.
            </p>
            <p>
              Our most valuable asset is our customers, and that's why we prioritize listening 
              to their needs and taking care of them. Our goal is to offer them exactly what they require, 
              leveraging our technical expertise and experience to assist them.
            </p>
            <p>
              Lighting is the most crucial factor in home decoration. 
              It is what determines the effectiveness and elegance of a well-crafted design. 
              The best design can be entirely lost with poor lighting.
            </p>
            <p>
              At <b>LUXURY LED LIGHTING</b>, we're not just a business. We're a partner in your LED journey. 
              We invite you to explore our world of LED innovation and discover how we can illuminate your path. 
              Thank you for being a part of our story.
            </p>
            <p>
              <h2>
                Your Bright Future Begins Here.
              </h2>
            </p>
        </div>
      </div>
      
    </div> 
    //end about container
  )
}

export default AboutUs;