import left_image from '../images/ceiling_magnetic_tracks_prducts.jpg';
import right_image from '../images/linear_lighting_square.jpg';
import products_header from '../images/products_header.png'
const Products = () => {
  return(
    <div className="main-products-container">
      <div className="images-container">
        <img src={products_header} alt="" />
]      </div>
      <div className="description-container"></div>
      <div className="subtitle-container"></div>
      <div className="categories-container"></div>
    </div>
  )
}

export default Products;