import stores from '../json/stores.json';
import '../css/distributors.css';
import Store from './Store.jsx';

const Distributors = () => {
  return(

    <div className='distributors-main-container'>
      <div>Distributors</div>
      <div className="distributors-list-container">
        {
          stores.map(el => {
            return(<Store data={el}/>)
          })
        }
      </div>
    </div>
  )
}

export default Distributors;